import React, { useEffect } from 'react';
import Main from './Main';
import { ContextWrapper } from './OverlayContext';
import style from './overlay.module.scss';

export default function Overlay() {
  useEffect(() => {
    // @ts-ignore
    document.body.classList.add(style['override']);
  });

  return (
    <ContextWrapper>
      <Main />
    </ContextWrapper>
  );
}
