import clsx from 'clsx';
import React from 'react';
import style from './loadingCircle.module.scss';

export default function LoadingCircle(props: { minified?: boolean }): JSX.Element {
  return (
    <div className={clsx({ [style.container]: !props.minified })}>
      <div className={clsx(style['loader-container'], style['loader'])}>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
