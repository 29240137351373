import clsx from 'clsx';
import React from 'react';
import textStyle from '../styles/text.module.scss';
import style from './error.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const Error = ({
  customStyle = {},
  children,
}: {
  customStyle?: Record<string, string | number>;
  children: JSX.Element | string | undefined;
}): JSX.Element => {
  return children ? (
    <div style={customStyle} className={clsx(textStyle.text, style.error)}>
      {children}
    </div>
  ) : (
    <></>
  );
};
