export const TermsOfServicesLink =
  'https://conscent-static-assets.s3.ap-south-1.amazonaws.com/ConsCent+Terms+of+Services.pdf';
export const MIN_API_USER_TOKEN_VERSION = 4;

export const viewActionEnums = [
  'ACCESS',
  'SCROLL',
  'LOGIN',
  'OTP',
  'GOOGLE',
  // -- views may reset beyond this point if user selects subscription and then comes back to select micropricing
  'CONTENT',
  'PASS',
  'SUB',
  'CHOOSE-SUB',
  'REVIEW-SUB',
  'AUTO-RENEW-SUB',
  'ADDRESS',
  'ADD-MONEY',
  'PURCHASE',
  'COUPON',
  'REDEEM-COUPON',
] as const;

export type userActions = typeof viewActionEnums[number];

export const cohortTypes = [
  'PURCHASE_AMOUNT',
  'PURCHASE_COUNT',
  'COUNTRY',
  'DEVICE',
  'OS',
  'USER_JOINING_DATE',
  'UTM_SOURCE',
  'VISIT_COUNT',
  'PREMIUM_VISIT_COUNT',
  'AD_BLOCK_USER',
  'SUBSCRIBER',
  'WALLET_BALANCE',
  'BROWSER',
  'TIME_SPENT_ON_WEBSITE',
  'USER_LOGIN_TYPE',
  'CONTENT_BASED',
  'LAST_PURCHASED_DATE',
  'PROMOTIONAL_OPT_IN',
  'LOGGED_IN_USER',
];

export const compareType = [
  'PURCHASE_AMOUNT',
  'PURCHASE_COUNT',
  'VISIT_COUNT',
  'PREMIUM_VISIT_COUNT',
  'WALLET_BALANCE',
  'TIME_SPENT_ON_WEBSITE',
  'USER_JOINING_DATE',
  'LAST_PURCHASED_DATE',
];

export const includeType = ['COUNTRY', 'DEVICE', 'OS', 'UTM_SOURCE', 'BROWSER', 'CONTENT_BASED', 'USER_LOGIN_TYPE'];

export const userJourneyActivationFrequencyEnums = [
  {
    name: 'Session',
    value: 'SESSION',
  },
  {
    name: 'Every Page',
    value: 'PAGE_VISIT',
  },
  {
    name: '15 Minutes',
    value: '15_MINUTES',
  },
  {
    name: 'Hour',
    value: 'HOUR',
  },
  {
    name: 'Day',
    value: 'DAY',
  },
  {
    name: 'Week',
    value: 'WEEK',
  },
  {
    name: 'Month',
    value: 'MONTH',
  },
  {
    name: 'Lifetime',
    value: 'LIFETIME',
  },
];

export const radioType = ['PROMOTIONAL_OPT_IN', 'LOGGED_IN_USER', 'AD_BLOCK_USER', 'SUBSCRIBER'];

export const dateType = ['USER_JOINING_DATE', 'LAST_PURCHASED_DATE'];

export const commaSeperatedStringType = ['CONTENT_BASED', 'UTM_SOURCE'];

export const purchaseTypeEnums = [
  {
    name: 'Pay-Per-Use',
    value: 'CONTENT',
  },
  {
    name: 'Pass',
    value: 'PASS',
  },
  {
    name: 'Susbscritption',
    value: 'SUBSCRIPTION',
  },
  {
    name: 'All',
    value: '',
  },
];

export const SignInSignUpCustomizeStaticData = [
  {
    placeholder: 'Welocome Back!',
    title: 'Title',
    keyName: 'mainTitle',
    maxlength: 36,
  },
  {
    placeholder: 'Please Enter Your Sign In Details Below',
    title: 'Sub-title',
    keyName: 'subTitle',
    maxlength: 72,
  },
  {
    placeholder: 'Sign in / Sign up',
    title: 'Form title',
    keyName: 'formTitle',
    maxlength: 30,
  },
  {
    placeholder: 'Mobile / Email',
    title: 'Primary login text',
    keyName: 'primaryLogintext',
    maxlength: 26,
  },
  {
    placeholder: 'Get OTP',
    title: 'Button',
    keyName: 'buttonText',
    maxlength: 20,
  },
  {
    placeholder: 'Or Continue with',
    title: 'Divider text',
    keyName: 'dividerText',
    maxlength: 20,
  },
  {
    placeholder: 'Keep me signed in',
    title: 'Remember me text',
    keyName: 'rememberMeText',
    maxlength: 16,
  },
  {
    placeholder: 'Forgot Password ?',
    title: 'Forgot password text',
    keyName: 'forgotPasswordText',
    maxlength: 16,
  },
];

export const UserDetailsCustomizeStaticData = [
  {
    placeholder: 'More detail',
    title: 'Form title',
    key: 'formTitle',
  },
  {
    placeholder: 'Full name',
    title: 'Full name',
    key: 'fullName',
  },
  {
    placeholder: 'Email (Optional)',
    title: 'Secondary login text',
    key: 'secondaryLoginText',
  },
  {
    placeholder: 'Sign in',
    title: 'Button',
    key: 'button',
  },
];

export const OTPCustomizeStaticData = [
  {
    placeholder: 'Verify OTP',
    title: 'Form title',
    key: 'formTitle',
  },
  {
    placeholder: 'A code has been sent to',
    title: 'Form subtitle',
    key: 'subTitle',
  },
  {
    placeholder: 'Resend OTP in',
    title: 'Timer text',
    key: 'timerText',
  },
  {
    placeholder: 'Submit',
    title: 'Button',
    key: 'button',
  },
];
