import React from 'react';
import clsx from 'clsx';
import textStyle from '../styles/text.module.scss';
import conscentLogo from 'assets/Logo/ConsCent-LogoIcon.svg';
import conscentLogoMono from 'assets/conscent_logo_black.svg';

export default function Footer(props: { customText?: string; monochromeLogo?: boolean }) {
  return (
    <div>
      <div style={{ textAlign: 'center' }} className={clsx(textStyle.text)}>
        {props.customText || 'Access With'}
        <img
          src={props.monochromeLogo ? conscentLogoMono : conscentLogo}
          style={{ height: 7, marginLeft: 2, marginBottom: 2 }}
        />
      </div>
    </div>
  );
}
