import React from 'react';
import style from './customButton.module.scss';
import clsx from 'clsx';
import CSS from 'csstype';

export default function Button({
  children,
  customStyle,
  disabled,
  ...props
}: {
  children?: any;
  customStyle?: CSS.Properties;
  disabled?: boolean;
  onClick?: () => unknown;
  [key: string]: any;
}) {
  return (
    <button
      className={clsx(style['custom-button'], disabled && style.disabled)}
      style={customStyle || {}}
      {...props}
      onClick={() => {
        if (!disabled) props.onClick?.();
      }}
    >
      {children}
    </button>
  );
}
