export async function includeGtag(id: string) {
  const gaScript = document.createElement('script');
  gaScript.setAttribute('async', 'true');
  gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${id}`);

  const gaScript2 = document.createElement('script');
  gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${id}');`;
  document.head.appendChild(gaScript);
  document.head.appendChild(gaScript2);
  return gaScript2;
}

export function pageViewForIT() {
  // @ts-ignore
  window.gtag('config', 'AW-10877099323');
}

export function purchaseSubsEventForIToday({
  value,
  currency,
  transactionId,
}: {
  value: number;
  currency: string;
  transactionId: string;
}) {
  // @ts-ignore
  window.gtag('event', 'conversion', {
    send_to: 'AW-10877099323/0IpYCJLf7L0DELu6zcIo',
    value,
    currency,
    transaction_id: transactionId,
  });
}

export function addToCartEventForIToday() {
  // @ts-ignore
  window.gtag('event', 'conversion', { send_to: 'AW-352663545/EaV-COK0kqQDEPnvlKgB' });
}

export function purchaseEventGA(params: {
  utmSource: string;
  currency: string;
  purchaseId: string;
  contentId: string;
  contentTitle: string;
  price: number;
  couponCode?: string;
  userId?: string;
}) {
  // @ts-ignore
  window.gtag('event', 'purchase', {
    currency: params.currency,
    transaction_id: params.purchaseId,
    value: params.price,
    userId: params.userId,
    // affiliation: 'Google Store', // optional
    ...(params.couponCode ? { coupon: params.couponCode } : {}),
    // shipping: 3.33, // optional
    // tax: 2.22, // optional
    items: [
      {
        item_id: `${params.couponCode ? params.couponCode + '_' : ''}${params.utmSource ? params.utmSource + '_' : ''}${
          params.contentId
        }`,
        item_name: params.contentTitle,
        /* Optional */
        // affiliation: 'Google Store',
        // coupon: 'SUMMER_FUN',
        ...(params.couponCode ? { coupon: params.couponCode } : {}),
        currency: params.currency,
        // discount: 2.22,
        // index: 5,
        // item_brand: 'Google',
        // item_category: 'Apparel',
        // item_category2: 'Adult',
        // item_category3: 'Shirts',
        // item_category4: 'Crew',
        // item_category5: 'Short sleeve',
        // item_list_id: 'related_products',
        // item_list_name: 'Related Products',
        // item_variant: 'green',
        // location_id: 'L_12345',
        price: params.price,
        quantity: 1,
      },
    ],
  });
}
