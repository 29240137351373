import React, { useEffect, useState } from 'react';
import logoUrl from '../../UserOverlay/assets/icons/logo.png';
import style from '../login/LoginCustomisation.module.scss';
import API from 'api';
import UserInput from '../login/UserInput';
import { postMessage } from '../../UserOverlay/paywallUtils';
import { ReactComponent as EmailUser } from './../../../../src/assets/emailUser.svg';
import { ReactComponent as EmailGrayIcon } from './../../../../src/assets/emailGrayIcon.svg';
import { validateEmail, ValidateMobileNumber } from '../../../utils/validators';
import Button from 'components/CustomButton';
import { isValidSecondaryPhone } from 'utils/regularExpressions';
import { ReactComponent as EmailIcon } from '../../../assets/mail_icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/mobile_gray.svg';

const MoreDetails = ({
  type,
  data,
  loginType,
  userDetailConfig,
  onScreenChange,
  clientId,
  onMoreDetailsSubmit,
}: {
  type: string;
  data: any;
  loginType: string;
  userDetailConfig: any;
  onScreenChange: any;
  clientId: string;
  onMoreDetailsSubmit: any;
}) => {
  const [name, setName] = useState('');
  const [enableSubmitButton, setEnableSubmitButton] = useState(true);
  const [secondInput, setSecondInput] = useState('');
  const [validationError, setValidationError] = useState('');

  const signInBtnCustomStyle = {
    margin: '40px auto 0px',
    width: '370px',
    height: '48px',
    font: 'normal normal 500 16px/33px Montserrat',
  };
  useEffect(() => {
    if (data.enableNameInput === 'MANDATORY' && data.enableSecondaryInput === 'MANDATORY') {
      if (name.length >= 3 && secondInput.length >= 5) {
        setEnableSubmitButton(false);
      } else {
        setEnableSubmitButton(true);
      }
    } else if (data.enableNameInput === 'MANDATORY' && ['OPTIONAL', 'SKIP'].includes(data.enableSecondaryInput)) {
      if (name.length >= 3) {
        setEnableSubmitButton(false);
      } else {
        setEnableSubmitButton(true);
      }
    } else if (['OPTIONAL', 'SKIP'].includes(data.enableNameInput) && data.enableSecondaryInput === 'MANDATORY') {
      if (secondInput.length >= 5) {
        setEnableSubmitButton(false);
      } else {
        setEnableSubmitButton(true);
      }
    } else {
      setEnableSubmitButton(false);
    }
  }, [name, secondInput]);
  const sendData = async () => {
    const objd = {} as any;

    if (secondInput) {
      if (loginType === 'PHONE') {
        if (!validateEmail(secondInput)) return setValidationError('Please Enter a valid Email');
        objd.secondaryEmail = secondInput;
      } else {
        if (!isValidSecondaryPhone(secondInput)) return setValidationError('Please Enter a valid Phone number');
        objd.secondaryPhoneNumber = secondInput;
      }
    }

    const setDetailsResponse = await API.user.setDetails({
      ...(name && { name: name }),
      ...(secondInput && objd),
      clientId,
      // ...(addInfoDetails.secondaryPhoneNumber && { secondaryPhoneNumber: addInfoDetails.secondaryPhoneNumber }),
    });

    onMoreDetailsSubmit();
    // postMessage.LOGGEDIN(true, sessionId);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={style['moreDetailsContainer']} style={{ ...(type === 'PAYWALL' && { border: 'none' }) }}>
        <img className={style['logo']} src={data?.brandLogoUrl || logoUrl} alt="Logo" />
        <div className={style['message']} style={{ color: userDetailConfig?.formTitle?.color }}>
          {userDetailConfig?.formTitle?.text || 'More detail'}
        </div>
        {(data.enableNameInput === 'OPTIONAL' || data.enableNameInput === 'MANDATORY') && (
          <div style={{ margin: '20px auto 0px' }} className={style['inputFieldContainer']}>
            {<EmailUser className={style['userInput']} />}
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder={userDetailConfig?.fullName?.text || 'Full name'}
              className={style['inputField']}
              type="text"
              maxLength={35}
            />
          </div>
        )}
        {(data.enableSecondaryInput === 'OPTIONAL' || data.enableSecondaryInput === 'MANDATORY') && (
          <div style={{ margin: '20px auto 0px' }} className={style['inputFieldContainer']}>
            {loginType === 'PHONE' ? (
              <EmailIcon className={style['userInput']} />
            ) : (
              <PhoneIcon className={style['userInput']} />
            )}

            <input
              onChange={(e) => {
                setSecondInput(e.target.value);
              }}
              placeholder={userDetailConfig?.secondaryLoginText?.text || 'Email (Optional)'}
              className={style['inputField']}
              type="text"
              maxLength={35}
            />
          </div>
        )}
        <div style={{ display: 'flex', padding: '10px 0px 0px 28px', color: 'red' }}>
          <span>{validationError}</span>
        </div>

        <Button
          disabled={enableSubmitButton}
          customStyle={{
            ...signInBtnCustomStyle,
            backgroundColor: userDetailConfig?.button?.buttonColor,
            color: userDetailConfig?.button?.color,
            maxWidth: '100%',
          }}
          onClick={() => {
            sendData();
          }}
        >
          {userDetailConfig?.button?.text || 'Sign in'}
        </Button>

        {data.enableNameInput === 'OPTIONAL' && data.enableSecondaryInput === 'OPTIONAL' ? (
          <div
            onClick={() => {
              onMoreDetailsSubmit();
            }}
            className={style['dontHaveAccount']}
          >
            Skip
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MoreDetails;
