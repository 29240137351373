import clsx from 'clsx';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { isValidMobileNumber, isValidEmail } from 'utils/regularExpressions';
import style from './addInfo.module.scss';
import mailIcon from '../../assets/mail_icon.svg';
import personIcon from '../../assets/person_circle_filled.svg';
import { OverlayContext, ROUTES } from 'views/UserOverlay/OverlayContext';
import API from 'api';
import { Wrapper } from 'views/UserOverlay/components';
import { validateEmail, ValidateMobileNumber } from 'utils/validators';

interface secondaryDetailsData {
  name: string;
  email: string;
  mobile: string;
}

enum Status {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
  SKIP = 'SKIP',
}

const AddInfo = () => {
  const { routeData, setRoute, userDetails, clientDetails } = useContext(OverlayContext);
  const nameInputRef = useRef(null);

  const [data, setData] = useState({} as secondaryDetailsData);
  const [error, serError] = useState('');
  const [mandatoryFieilds, setMandatoryFields] = useState({ name: Status.OPTIONAL, secField: Status.OPTIONAL } as {
    name: Status.MANDATORY | Status.OPTIONAL | Status.SKIP;
    secField: Status.MANDATORY | Status.OPTIONAL | Status.SKIP;
  });

  const continueLogin = async () => {
    if (routeData.nextPage === 'balance') return setRoute(ROUTES.BALANCE);
    if (routeData.nextPage === 'confirmPayment') return setRoute(ROUTES.CONFIRM_PAYMENT);

    // error edge-case
    API.internal.LogError({
      key: 'Routing after continue login',
      message: `Default case invoked for user, ${userDetails.userId}, routeData: ${routeData}`,
    });
    return setRoute(ROUTES.CONFIRM_PAYMENT);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (mandatoryFieilds.name === Status.MANDATORY) {
      if (!data.name || data.name.length < 3 || data.name.length > 40) return serError('Please Enter a Valid Name');
    }

    if (mandatoryFieilds.secField === Status.MANDATORY) {
      if (userDetails.email) {
        if (!data.mobile) return serError('Mobile number is mandatory');
        if (!ValidateMobileNumber(data.mobile)) return serError('Please Enter a valid Mobile Number');
      } else {
        if (!data.email) return serError(' Email is mandatory');
        if (!validateEmail(data.email)) return serError('Please Enter a valid Email');
      }
    }

    if (mandatoryFieilds.secField === Status.OPTIONAL) {
      if (userDetails.email) {
        if (data.mobile && !ValidateMobileNumber(data.mobile)) return serError('Please Enter a valid Mobile Number');
      } else {
        if (data.email && !validateEmail(data.email)) return serError('Please Enter a valid Email');
      }
    }

    const setDetailsResponse = await API.user.setDetails({
      ...(data.name && { name: data.name }),
      ...(data.email && { secondaryEmail: data.email }),
      ...(data.mobile && { secondaryPhoneNumber: data.mobile }),
    });
    const details = setDetailsResponse.data.user;

    if (!API.validators.checkSuccessCode(setDetailsResponse)) return console.error(setDetailsResponse.data.message[0]);
    continueLogin();
  };

  useEffect(() => {
    (async () => {
      const fieldStatus = await API.client.PaywallCustomization({ clientId: clientDetails.clientId });

      if (API.validators.checkSuccessCode(fieldStatus)) {
        const fieldStatusData = fieldStatus.data?.paywallCustomization;
        const nameStatus = fieldStatusData?.enableNameInput;
        const secStatus = fieldStatusData?.enableSecondaryInput;

        if (nameStatus === Status.SKIP && secStatus === Status.SKIP) {
          continueLogin();
          return;
        } else {
          setMandatoryFields({
            name: fieldStatusData?.enableNameInput,
            secField: fieldStatusData?.enableSecondaryInput,
          });
        }
      }

      setData({ name: userDetails.name, email: userDetails.secondaryEmail, mobile: userDetails.secondaryPhoneNumber });
      // @ts-ignore
      nameInputRef?.current?.focus();
    })();
  }, []);

  return (
    <Wrapper hideCross>
      <div className={style['add-info-container']}>
        <form onSubmit={onSubmit}>
          <p className={style['add-info-header']}>For Better Experience</p>
          <p className={style['add-info-sub-text']}>Help us with some additional details</p>
          {mandatoryFieilds.name !== Status.SKIP && (
            <div className={style['input-container']}>
              <input
                pattern="[a-zA-Z'-'\s]*"
                className={clsx(style['add-info-input'], style['input-padding'])}
                type="text"
                placeholder={`Full Name ${mandatoryFieilds.name === Status.OPTIONAL ? '(Optional)' : ''}`}
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.currentTarget.value })}
                ref={nameInputRef}
              />
              <img src={personIcon} alt="person-icon"></img>
            </div>
          )}
          {mandatoryFieilds.secField !== Status.SKIP ? (
            userDetails.email ? (
              <div className={clsx(style['input-container'], style['position-relative'])}>
                <input
                  className={clsx(style['add-info-input'], style['padding-for-cc'])}
                  type="text"
                  placeholder={`Mobile Number ${mandatoryFieilds.secField === Status.OPTIONAL ? '(Optional)' : ''}`}
                  onChange={(e) => setData({ ...data, mobile: e.currentTarget.value })}
                  value={data.mobile}
                  maxLength={10}
                />
                <span>+91</span>
              </div>
            ) : (
              <div className={style['input-container']}>
                <input
                  className={clsx(style['add-info-input'], style['input-padding'])}
                  type="email"
                  placeholder={`Email ${mandatoryFieilds.secField === Status.OPTIONAL ? '(Optional)' : ''}`}
                  onChange={(e) => setData({ ...data, email: e.currentTarget.value })}
                  value={data.email}
                />
                <img src={mailIcon} alt="email-icon"></img>
              </div>
            )
          ) : (
            ''
          )}
          <p className={style['error-msg']}>{error}</p>
          <button className={style['add-info-submit']} type="submit">
            Next
          </button>
        </form>
      </div>
    </Wrapper>
  );
};

export default AddInfo;
