import API from 'api';
import moment from 'moment';

export function iterateAndFormatDate(objs: any[], key: string, format?: string) {
  objs.forEach((obj) => {
    obj[key] = moment(obj[key]).format(format || 'Do MMMM h:ma');
  });
  return objs;
}

export function formatDateTime(d: string) {
  return moment(d).format('Do MMMM HH:mm');
}
export function formatDateTimeForUser(d: string) {
  return moment(d).format('Do MMMM YYYY hh:mm a');
}

export function formatDate(d: string) {
  return moment(d).format('Do MMMM YYYY');
}
export function formatMonth(d: string) {
  return moment(d).format('MMMM YYYY');
}
export function formatDateForUser(d: string) {
  return moment(d).format('DD-MM-YYYY');
}

export function logError(error: Record<string, any>) {
  return API.internal.LogError(error);
}

export const consts = {
  CLIENT_HOME_PATH: '/client/dashboard',
  ADMIN_HOME_PATH: '/admin/dashboard',
  USER_HOME_PATH: '/dashboard',
  GENERIC_ERROR_PATH: '/error',
  USER_OVERLAY_PATH: '/overlay',
  USER_OVERLAY_METER: '/Meter',
  CHECK_USER_STATUS: '/checkuserStatus',
  USER_LOGIN_CHECK_PATH: '/login-check',
  USER_COOKIE_CHECK_PATH: '/cookie-check',
  USER_DONATION_PATH: '/donation',
  USER_SUBSCRIPTION_PATH: '/subscription',
  USER_CONSCENT_LOGIN: '/conscent-login',
  USER_BUNDLED_SUBSCRIPTION_PATH: '/bundle-subscriptions',
  USER_EDIT_SUBS: '/REVIEW_SUBSCRIPTION',
  CLIENT_SUBSCRIPTION_CONFIG: '/client/dashboard/subscription_configuration',
  AUTO_LOGIN_USER: '/auto-login-user',
  USER_STATUS: '/user-status',
  GOOGLE_LOGIN: '/google-login',
  GOOGLE_ONETAP_LOGIN: '/google-onetap-login',
  LOGIN_WITH_TOKEN: '/login-with-token',
};
