import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
interface AuthDetails {
  phoneNumber?: string; // phone number is used by the user
  userId?: string;
  token: string;
  email?: string;
  userType: 'USER' | 'CLIENT' | 'ADMIN';
  clientId?: string;
  clientName?: string;
  sessionId?: string; // sessionId is used by the user
  expiresAt: string;
  version: number;
  clientDetails?: { clientId: string; token: string; name: string; image: string }[];
  globalUTCOffset?: number;
}

const initialState: AuthDetails = {
  phoneNumber: '',
  userId: '',
  token: '',
  userType: 'USER',
  email: '',
  clientId: '',
  expiresAt: '',
  version: 0,
  globalUTCOffset: 0,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthDetails(state, action: PayloadAction<AuthDetails>) {
      state.phoneNumber = action.payload.phoneNumber;
      state.userId = action.payload.userId;
      state.token = action.payload.token;
      state.userType = action.payload.userType;
      state.clientId = action.payload.clientId;
      state.clientName = action.payload.clientName;
      state.email = action.payload.email;
      state.expiresAt = action.payload.expiresAt;
      state.sessionId = action.payload.sessionId || '';
      state.version = action.payload.version;
      state.clientDetails = action.payload.clientDetails;
      state.globalUTCOffset = action.payload.globalUTCOffset;
    },
    clearAuthDetails(state) {
      state.phoneNumber = '';
      state.token = '';
      state.userId = '';
      state.userType = 'USER';
      state.clientId = '';
      state.clientName = '';
      state.email = '';
      state.sessionId = '';
      state.expiresAt = '';
      state.version = 0;
      state.clientDetails = [];
    },
  },
});

export const { setAuthDetails, clearAuthDetails } = auth.actions;

export default auth.reducer;
