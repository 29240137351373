declare global {
  interface Window {
    fbq: any;
  }
}
export const fbPixelConfig = (event: string, data?: any) => {
  if (window.fbq) window.fbq('trackSingle', process.env.REACT_APP_FB_PIXEL_ID, event, data);
};

export function includeFacebookPixel(id: string) {
  const facebookPixelScript = document.createElement('noscript') as any;
  facebookPixelScript.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
  facebookPixelScript.height = '1';
  facebookPixelScript.width = '1';
  facebookPixelScript.style = 'display: none;';
  document.body.appendChild(facebookPixelScript);
  window.fbq('init', id);
  return facebookPixelScript;
}

export function includeFacebookPixelForIT(id: string) {
  const facebookPixelScript = document.createElement('noscript') as any;
  facebookPixelScript.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
  facebookPixelScript.height = '1';
  facebookPixelScript.width = '1';
  facebookPixelScript.style = 'display: none;';
  document.body.appendChild(facebookPixelScript);
  window.fbq('init', id);
  window.fbq('track', 'PageView');
  return facebookPixelScript;
}

export function fireFacebookPixel(id: string, event: string, data?: any) {
  const facebookScript = window.fbq?.getState()?.pixels.find((o: any) => o.id === id);
  if (!facebookScript) {
    includeFacebookPixel(id);
  }
  if (window.fbq) {
    window.fbq('trackSingle', id, event, data);
  } else {
    console.log('Pixel not initalized correctly');
  }
}

export function fireFacebookConversionPixelForIT(id: string, amount: number, currency: string) {
  const facebookScript = window.fbq?.getState()?.pixels.find((o: any) => o.id === id);
  if (!facebookScript) {
    includeFacebookPixelForIT(id);
  }
  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      value: amount,
      currency: currency,
    });
  } else {
    console.log('Pixel not initalized correctly');
  }
}

export function fireFacebookAddToCartPixelForIT(id: string, amount: number, currency: string) {
  const facebookScript = window.fbq?.getState()?.pixels.find((o: any) => o.id === id);
  if (!facebookScript) {
    includeFacebookPixelForIT(id);
  }
  if (window.fbq) {
    window.fbq('track', 'AddToCart', { currency, value: amount });
  } else {
    console.log('Pixel not initalized correctly');
  }
}
