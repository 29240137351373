import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import API from 'api';
import EmailLogin from './EmailLogin';
import { phoneLogin, emailLogin } from './authUtils';
import { ConscentLoginContext } from '../ConscentLogin/ConscentLoginContext';
import uuidv4 from 'utils/Tracker/track';
import style from './login.module.scss';
import { RootState } from 'app/rootReducer';
import { consts } from 'utils';
import { clearAuthDetails, setAuthDetails } from './authSlice';
import logo from '../../assets/Logo/Logo@2x.png';
import UserSignUpAndLogin from 'views/ClientDashboard/loginUI/UserSignUpAndLogin';
import VerifyOTP from 'views/ClientDashboard/loginUI/VerifyOTP';
import { getDefaultTrackingValues } from 'utils/Tracker/track';
import { eventLocation, eventType } from 'utils/Tracker/track';
import { setAnalyticsData } from '../../app/analyticsSlice';
import { addQueryParamToUrl } from 'views/UserOverlay/paywallUtils';
enum SCREENS {
  LOGIN = 'login',
  OTP = 'otp',
}

function AuthWrapper({ children }: { children: JSX.Element }) {
  return <>{children}</>;
}

export default function AuthParent() {
  const { setUserDetails } = useContext(ConscentLoginContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const authStore = useSelector((store: RootState) => store.auth);
  const subdomainType = useSelector((store: RootState) => store.config.subdomainType);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const searchParams = new URLSearchParams(location.search);
  const [screen, setScreen] = useState(SCREENS.LOGIN as SCREENS);
  const [clientId, setClientId] = useState('');
  const [error, setError] = useState('');

  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loginType, setLoginType] = useState<'PHONE' | 'EMAIL'>('PHONE');
  const [data, setdata] = useState({
    enableEmailLogin: true,
    enableMobileLogin: true,
    enableFacebookLogin: true,
    enableGoogleLogin: true,
    enableNameInput: 'SKIP',
    enableSecondaryInput: 'SKIP',
    brandLogoUrl: '',
    uiConfig: {},
  });
  if (analyticsStore.anonId === '00000000-0000-0000-0000-000000000000') {
    const anonId = uuidv4();
    dispatch(setAnalyticsData({ anonId }));
  }

  const [uiConfig, setUiConfig] = useState({} as any);
  const [otpConfig, setOtpConfig] = useState({} as any);
  const anonId = searchParams.get('anonId') || '';
  const sendOTP = async () => {
    if (loginType === 'PHONE') {
      const { accountDetails } = await phoneLogin({
        phoneNumber: userPhone,
        clientId: clientId,
        isClientLoginFlow: true,
        eventLocation: eventLocation.UDB_LOGIN,
        anonId: anonId,
      });
      udbOTPView();
      udbLoginClick('MOBILE');
      // firstTimeLogin = accountDetails.alreadyRegistered;
    } else {
      const { accountDetails } = await emailLogin({
        email: userEmail,
        isClientLoginFlow: true,
        eventLocation: eventLocation.UDB_LOGIN,
        anonId: anonId,
      });
      udbOTPView();
      udbLoginClick('EMAIL');
    }
  };
  const submitOTP = async (otp: string) => {
    // setShowResendCode(false);
    const loginResponse = await API.auth.LoginOtp({
      email: loginType === 'EMAIL' ? userEmail : undefined,
      phoneNumber: loginType === 'PHONE' ? userPhone : undefined,
      OTP: otp,
      clientId,
      eventLocation: eventLocation.UDB_LOGIN,
      anonId: anonId,
    });
    if (API.validators.matchStatusCode(loginResponse, 401)) return setError(loginResponse.data.message);
    if (API.validators.checkSuccessCode(loginResponse)) {
      const { token, email, userId, sessionId, expiresAt, version } = loginResponse.data as any;
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, userType: 'USER', version }));
      const userDetailsResponse = await API.user.Details();
      if (!API.validators.checkSuccessCode(userDetailsResponse)) {
        throw { message: 'Unable to fetch user details' };
      }

      const name = userDetailsResponse.data.name;
      setUserDetails({
        loggedIn: true,
        walletBalance: parseFloat(userDetailsResponse.data.wallet['balance']['$numberDecimal']),
        phoneNumber: userDetailsResponse.data.phoneNumber,
        email: userDetailsResponse.data.email,
        name,
        userId: userDetailsResponse.data._id,
        walletCurrency: userDetailsResponse.data.wallet['currency'],
      });
    } else {
      setError(loginResponse.data.message);
    }
  };
  const onScreenChange = (screentype: SCREENS) => {
    setScreen(screentype);
    sendOTP();
  };

  const udbLoginView = () => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.UDB_LOGIN,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const udbOTPView = () => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.UDB_OTP,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const udbLoginClick = (loginType: 'EMAIL' | 'MOBILE' | 'GOOGLE') => {
    const ssoLoginClickObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.UDB_LOGIN,
      clickAction: loginType,
      clientId: clientId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginClickObject });
  };
  const clientRedirectUrl = searchParams.get('clientRedirectUrl');

  useEffect(() => {
    (async () => {
      const userTokenResponse = searchParams.get('userTokenResponse');
      if (userTokenResponse) {
        const userResponse = JSON.parse(userTokenResponse);
        dispatch(setAuthDetails({ ...userResponse, userType: 'USER' }));
        const clientId = searchParams.get('clientId') as string;

        const pageUrl = searchParams.get('pageUrl'); //for facebook

        if (pageUrl) {
          const tempauthdata = (await API.user.GenerateTempTokenWithUserAuth({ clientId })).data;

          const x = {
            token: tempauthdata.tempAuthToken,
            email: userResponse?.email,
            phone: userResponse?.phoneNumber,
            clientId,
          };
          const data = window.btoa(JSON.stringify(x));
          // finalRedirectUrl = addQueryParamToUrl({ key: '_csc-sessionId', value: sessionId || '' }, finalRedirectUrl);
          const finalRedirectUrl = addQueryParamToUrl({ key: 'tempAuthToken', value: data }, window.atob(pageUrl));

          console.log('dsss', finalRedirectUrl);

          window.location.href = decodeURIComponent(finalRedirectUrl);
          return;
        }

        const loginSiteUrl = sessionStorage.getItem('loginSiteUrl'); //for google
        if (loginSiteUrl) {
          const loginSiteUrlSearchParams = new URLSearchParams(loginSiteUrl);
          (async () => {
            if (loginSiteUrlSearchParams.get('clientId')) {
              await API.user.UpdateLoggedInClients({ clientId: loginSiteUrlSearchParams.get('clientId') as string });
            }
          })();

          window.location.href = loginSiteUrl;
        } else {
          return history.push(consts.USER_HOME_PATH);
        }
      }
      if (authStore.token) {
        if (subdomainType && subdomainType !== authStore.userType) dispatch(clearAuthDetails());
        else {
          if (authStore.userType === 'USER') history.push(consts.USER_HOME_PATH);
          if (authStore.userType === 'CLIENT') history.push(consts.CLIENT_HOME_PATH);
          if (authStore.userType === 'ADMIN') history.push(consts.ADMIN_HOME_PATH);
        }
      }
    })();
  }, []);
  useEffect(() => {
    udbLoginView();
  }, []);

  // subdomain based auth
  if (subdomainType) {
    if (subdomainType === 'USER') {
      return (
        <>
          {screen === SCREENS.LOGIN && (
            <div className={style['form-container']}>
              <UserSignUpAndLogin
                type="UDB"
                clientId={clientId}
                setPhoneNumber={setUserPhone}
                setUserEmail={setUserEmail}
                setLoginType={setLoginType}
                onScreenChange={onScreenChange}
                data={data}
                uiConfig={uiConfig}
                loginClick={udbLoginClick}
              />
            </div>
          )}
          {screen === SCREENS.OTP && (
            <>
              <VerifyOTP
                clientId={clientId}
                type="SSO"
                data={data}
                userPhoneNumber={userPhone}
                userEmail={userEmail}
                loginType={loginType}
                otpConfig={otpConfig}
                submitOTP={submitOTP}
                setError={setError}
                error={error}
              />
            </>
          )}
        </>
      );
    } else if (subdomainType === 'CLIENT') {
      return (
        <AuthWrapper>
          <div className={clsx(style['form-container'], style['background-blue'])}>
            <div className={style['border-container']}>
              <EmailLogin loginType="CLIENT" />
            </div>
          </div>
        </AuthWrapper>
      );
    } else if (subdomainType === 'ADMIN') {
      return (
        <AuthWrapper>
          <div className={clsx(style['form-container'], style['background-blue'])}>
            <div className={style['border-container']}>
              <EmailLogin loginType="ADMIN" />
            </div>
          </div>
        </AuthWrapper>
      );
    }
  }

  // non-subdomain based auth
  return (
    <AuthWrapper>
      <Switch>
        <Route path="/client" exact>
          <div className={clsx(style['form-container'], style['background-blue'])}>
            <div className={style['border-container']}>
              <EmailLogin loginType="CLIENT" />
            </div>
          </div>
        </Route>
        <Route path="/admin" exact>
          <div className={clsx(style['form-container'], style['background-blue'])}>
            <div className={style['border-container']}>
              <EmailLogin loginType="ADMIN" />
            </div>
          </div>
        </Route>

        <Route path="/" exact>
          <div className={clsx(style['form-container'], style['background-white'])}>
            {screen === SCREENS.LOGIN && (
              <div className={style['container']}>
                <UserSignUpAndLogin
                  type="UDB"
                  clientId={clientId}
                  setPhoneNumber={setUserPhone}
                  setUserEmail={setUserEmail}
                  setLoginType={setLoginType}
                  onScreenChange={onScreenChange}
                  data={data}
                  uiConfig={uiConfig}
                  loginClick={udbLoginClick}
                  conscentLogo={true}
                />
              </div>
            )}
            {screen === SCREENS.OTP && (
              <>
                <VerifyOTP
                  clientId={clientId}
                  type="SSO"
                  data={data}
                  userPhoneNumber={userPhone}
                  userEmail={userEmail}
                  loginType={loginType}
                  otpConfig={otpConfig}
                  submitOTP={submitOTP}
                  setError={setError}
                  error={error}
                />
              </>
            )}
          </div>
        </Route>
      </Switch>
    </AuthWrapper>
  );
}
