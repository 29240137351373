import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'views/Auth/authSlice';
import configReducer from './configslice';
import analyticsReducer from './analyticsSlice';
import globalCurrencyReducer from './globalCurrency';

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  analytics: analyticsReducer,
  globalCurrency: globalCurrencyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
