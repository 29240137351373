import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Wrapper } from '../components';
import style from './transition.module.scss';
import textStyle from '../styles/text.module.scss';
import ConsCentLogo from '../../../assets/Logo/ConsCent-LogoIcon.svg';
import { OverlayContext } from '../OverlayContext';

export default function TransitionThankyou() {
  const { notifyClientSuccess, routeData } = useContext(OverlayContext);
  useEffect(() => {
    setTimeout(function () {
      //@ts-ignore
      notifyClientSuccess(routeData);
    }, 1000);
  });

  return (
    <Wrapper hideCross={true} hideFooter={false}>
      <div className={style['transition-container']}>
        <div className={textStyle['heading']} style={{ marginTop: 80 }}>
          Thank You!
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <img src={ConsCentLogo} className={style['logo']} />
          <div className={clsx(textStyle['heading'])}>Your Gateway To Premium Content</div>
        </div>
      </div>
    </Wrapper>
  );
}
