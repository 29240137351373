import React from 'react';
import { Button } from '.';
import clsx from 'clsx';
import textStyle from '../styles/text.module.scss';
import conscentLogo from 'assets/Logo/ConsCent-LogoIcon.svg';
import conscentLogoMono from 'assets/conscentLogoMono.png';
import welcomeStyle from '../views/welcome.module.scss';
import CSS from 'csstype';

export function ButtonWithFooter(props: {
  buttonText: string;
  onClickFunction: (e: Event) => any;
  id: any;
  disabled?: boolean;
  monochromeLogo?: boolean;
  transparentBackground?: boolean;
  customTextStyle?: CSS.Properties;
  backgroundColor?: string;
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: 'fit-content' }}>
        <Button
          id={props.id}
          buttonText={props.buttonText}
          customStyle={{
            marginTop: '24px',
            marginBottom: '5px',
            letterSpacing: '1px',
            backgroundColor: props.transparentBackground ? 'transparent' : props.backgroundColor,
            color: props.transparentBackground ? 'rgb(60, 70, 92)' : '',
            border: props.transparentBackground ? `1px solid #707070` : '',
            borderRadius: '14px',
          }}
          customMobileStyle={{
            marginTop: '24px',
            backgroundColor: props.transparentBackground ? 'transparent' : props.backgroundColor,
            color: props.transparentBackground ? 'rgb(60, 70, 92)' : '',
            border: props.transparentBackground ? `1px solid #707070` : '',
            marginBottom: '5px',
            borderRadius: '14px',
            width: '250px',
            height: '45px',
          }}
          customTextStyle={props.customTextStyle}
          fixSize={true}
          onClickFunction={props.onClickFunction}
          disabled={props.disabled}
        />

        <span className={(clsx(textStyle.text), welcomeStyle['powered-by-text'])}>
          Pay with{' '}
          <img src={props.monochromeLogo ? conscentLogoMono : conscentLogo} style={{ height: 10, marginBottom: 2 }} />
        </span>
      </div>
    </div>
  );
}

export function PayWithConscentBlack({ customTextStyle }: { customTextStyle?: CSS.Properties }) {
  return (
    <div style={customTextStyle}>
      <div className={(clsx(textStyle.text), welcomeStyle['powered-by-black-text'])}>
        Pay with <img src={conscentLogoMono} style={{ height: 10, marginBottom: 2 }} />
      </div>
    </div>
  );
}
