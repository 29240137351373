import ReactGA from 'react-ga4';

export const fireGa4 = ({
  id,
  eventName,
  userId,
  eventParams,
}: {
  id: string;
  eventName: any;
  eventParams: Record<any, any>;
  userId?: string;
}) => {
  if (!id) return;

  ReactGA.initialize(id);

  ReactGA.gtag('set', {
    user_id: userId || null,
  });

  ReactGA.gtag('event', eventName, {
    ...eventParams,
  });
};

export const getEnum = {
  CONTENT: 'content',
  PASS: 'pass',
  SUBS: 'subscription',
};
