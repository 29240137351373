interface ValidationRules {
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
}

export const validate = (item: any, rules: ValidationRules): boolean => {
  if (rules.minLength && item.length < rules.minLength) return false;
  if (rules.maxLength && item.length > rules.maxLength) return false;
  if (rules.pattern && !rules.pattern.test(item)) return false;
  return true;
};
