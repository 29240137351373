import React from 'react';
import { OverlayContext, PURCHASE_MODE } from '../OverlayContext';
import textStyle from '../styles/text.module.scss';
import CSS from 'csstype';

export const DurationSubtext = (props: { customStyle?: CSS.Properties }): JSX.Element => {
  const overlayContext = React.useContext(OverlayContext);
  const { purchaseMode, userDetails } = overlayContext;
  return (
    <div
      className={textStyle['subHeading']}
      style={{
        ...props.customStyle,
      }}
    >
      {purchaseMode === PURCHASE_MODE.CONTENT && (
        <>
          Once purchased, this {userDetails.contentIsBundle ? 'issue' : userDetails.contentType.toLowerCase()} is free
          for{' '}
          {userDetails.contentDuration === 1
            ? userDetails.contentDuration + ' day'
            : userDetails.contentDuration + ' days'}
        </>
      )}
      {purchaseMode === PURCHASE_MODE.PASS && (
        <>
          Once purchased, {userDetails.clientName}&apos;s premium content is free for{' '}
          {userDetails.passDuration === 1
            ? userDetails.contentDuration + ' hour'
            : userDetails.contentDuration + ' hours'}
        </>
      )}
    </div>
  );
};
