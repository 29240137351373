import React, { useContext, useEffect, useState, useRef } from 'react';
import { Wrapper } from '../components';
import { OverlayContext, PURCHASE_MODE } from '../OverlayContext';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import style from './paypalPayment.module.scss';
import API from 'api';
import { antdMessageError } from 'utils/antdMessageComponent';
import { eventLocation } from '../../../utils/Tracker/track';
import LoaderForPaywall from '../components/loaders/LoaderForPaywall';

const PaypalPayment = () => {
  const overlayContext = useContext(OverlayContext);
  const { clientDetails, viewId, purchaseMode, userDetails, contentPurchaseType } = overlayContext;
  const authDetails = useSelector((store: RootState) => store.auth);
  const searchParams = new URLSearchParams(location.search);
  const winRef = useRef<Window | null>(null);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const [popupBlock, setPopupBlock] = useState(false);
  const [createOrderData, setCreateOrderData] = useState({
    redirectUrl: '',
    transactionId: '',
  });
  const [alreadyBtnClick, setAlreadyBtnClick] = useState(false);
  const [currency, setCurrency] = useState(userDetails.contentCurrency);
  let timer: any;
  let checkPaymentStatusTimer: any;
  const contentPrice = Math.ceil(userDetails.contentPrice * 100) / 100;
  const [convertedContentPrice, setConvertedContentPrice] = useState(contentPrice);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId') || '';
  const successFrontendUrl = new URL(window.location.href);
  successFrontendUrl.searchParams.append('paypal', 'true');
  const contentDetails = {
    eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
    firstTimeLogin: !analyticsStore.alreadyRegistered ? 1 : 0,
    anonId: anonId,
    clientId: clientDetails.clientId,
    clientContentId: clientDetails.clientContentId,
    price: convertedContentPrice,
    currency: currency,
    userId: authDetails.userId,
    paywallId,
    paywallType: analyticsStore.paywallType,
    journeyId: localStorage.getItem('journeyId'),
    templateId: localStorage.getItem('templateId') || '',
    successFrontendUrl: successFrontendUrl,
    type: purchaseMode,
    contentPurchaseType,
    failureFrontendUrl: `${window.location.href}`.includes('&status=FAILURE')
      ? `${window.location.href}`
      : `${window.location.href}&status=FAILURE`,
    popupId: userDetails.popupId,
  } as Record<string, any>;

  const updateContentPrice = async (currencyISOCode: string) => {
    let updatedContentPrice = purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice;
    if (userDetails.contentCurrency !== currencyISOCode) {
      const updatedContentPriceResponse = await API.content.convertCurrency({
        from: userDetails.contentCurrency,
        to: currencyISOCode,
        amount: purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice,
      });
      updatedContentPrice = updatedContentPriceResponse.data;
    }
    setCurrency(currencyISOCode);
    setConvertedContentPrice(updatedContentPrice);
  };

  const showPaypalPopup = (url: string, transactionIdRes: string) => {
    const left = window.screen.width / 2;
    const top = window.screen.height / 2;
    const popWidth = 800;
    const popHeight = 600;
    const popTop = top - popHeight / 2;
    const popLeft = left - popWidth / 2;
    const newWin = window.open(
      url,
      'PAYPAL_WINDOW',
      `height=${popHeight},width=${popWidth},resizeable=0,top=${popTop},left=${popLeft}`,
    );

    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      setPopupBlock(true);
    } else {
      // Popup wasn't blocked, focus on it
      winRef.current = newWin;
      newWin.focus();
    }
    checkPaymentStatusTimer = setInterval(() => {
      checkPaymentStatus(transactionIdRes);
    }, 2000);
  };
  // check payment status
  const checkPaymentStatus = async (id: string) => {
    try {
      if (id) {
        const transactionStatus = await API.user.transitionStatus({ id: id });
        if (transactionStatus.data.data.status === 'COMPLETE') {
          if (winRef.current && !winRef.current.closed) {
            winRef.current.close();
            window.location.reload();
            clearInterval(timer);
            clearInterval(checkPaymentStatusTimer);
          }
        }
      }
    } catch (error) {
      console.error('Error occurred while checking PayPal payment status:', error);
    }
  };

  const callPaypal = async () => {
    let paypalRedirectUrlRes = '';
    let transactionIdRes = '';
    const callPaypalPrams = {
      clientId: clientDetails.clientId,
      clientContentId: clientDetails.clientContentId,
      type: purchaseMode,
      contentDetails,
    };

    const paypalOrderResponse = await API.user.payment.CallPaypal({ callPaypalPrams });
    if (!API.validators.checkSuccessCode(paypalOrderResponse)) return antdMessageError('Unable To Update Segment');
    paypalRedirectUrlRes = paypalOrderResponse?.data?.data?.paypalRedirectUrl;
    transactionIdRes = paypalOrderResponse?.data?.data?.transactionId;

    setCreateOrderData({
      redirectUrl: paypalRedirectUrlRes,
      transactionId: transactionIdRes,
    });
    // showPaypalPopup(paypalRedirectUrlRes, transactionIdRes);
  };
  const reopenMinimizedWindow = (url: string, transactionIdRes: string) => {
    if (winRef.current) {
      winRef.current.focus();
    } else {
      showPaypalPopup(url, transactionIdRes);
    }
  };
  const polling = () => {
    if (winRef.current) {
      if (winRef.current.closed) {
        setPopupBlock(false);
        setCreateOrderData({
          redirectUrl: '',
          transactionId: '',
        });
        clearInterval(timer);
        clearInterval(checkPaymentStatusTimer);

        window.location.reload();
      }
    }
  };
  useEffect(() => {
    timer = setInterval(polling, 1000, 3);

    return () => {
      clearInterval(timer);
      clearInterval(checkPaymentStatusTimer);
    };
  }, []);

  useEffect(() => {
    callPaypal();
  }, []);
  useEffect(() => {
    updateContentPrice(userDetails.contentCurrency);
  }, [purchaseMode]);
  const DurationSubtext = () => {
    return (
      <>
        {purchaseMode === PURCHASE_MODE.CONTENT && (
          <>
            {userDetails.contentDuration === 1
              ? userDetails.contentDuration + ' day'
              : userDetails.contentDuration + ' days'}
          </>
        )}
        {purchaseMode === PURCHASE_MODE.PASS && (
          <>
            {userDetails.passDuration === 1
              ? userDetails.contentDuration + ' day'
              : userDetails.contentDuration + ' days'}
          </>
        )}
      </>
    );
  };
  return (
    <>
      {createOrderData.redirectUrl && !alreadyBtnClick ? (
        <Wrapper hideFooter paypal>
          <div style={{ margin: '32px 0px', width: '100%' }}>
            <p className={style['title']}>Purchase Details</p>
            <p className={style['sub-title']}>
              Plan type: <span>{purchaseMode === PURCHASE_MODE.CONTENT ? 'Pay-Per-Use' : purchaseMode}</span>
            </p>
            <p className={style['story-title']}>{userDetails.contentTitle}</p>
            <p className={style['sub-title']}>Duration: {DurationSubtext()}</p>
            {popupBlock ? (
              <button
                className={style['submit-btn']}
                onClick={() => {
                  setAlreadyBtnClick(true);
                  showPaypalPopup(createOrderData.redirectUrl, createOrderData.transactionId);
                }}
              >
                Confirm Payment
              </button>
            ) : (
              <button
                className={style['submit-btn']}
                onClick={() => {
                  setAlreadyBtnClick(true);
                  reopenMinimizedWindow(createOrderData.redirectUrl, createOrderData.transactionId);
                }}
              >
                Click to continue
              </button>
            )}
          </div>
        </Wrapper>
      ) : (
        <div className={style['container']}>
          <div style={{ width: '70%' }}>
            <LoaderForPaywall />
            {alreadyBtnClick && (
              <p className={style['progress-text']}>
                {`Please don't refresh or leave this page. Your purchase is being processed.`}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default PaypalPayment;
