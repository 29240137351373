import React, { useEffect, useContext } from 'react';
import { OverlayContext, ROUTES } from '../OverlayContext';
import { Wrapper } from '../components';
import style from './transition.module.scss';
import textStyle from '../styles/text.module.scss';
import ConsCentLogo from '../../../assets/Logo/ConsCent-LogoIcon.svg';

export default function TransitionWelcome() {
  const { setRoute, setRouteData } = useContext(OverlayContext);
  useEffect(() => {
    setTimeout(function () {
      setRouteData({ showLowBalance: false });
      return setRoute(ROUTES.BALANCE);
    }, 1000);
  }, []);
  return (
    <Wrapper hideCross={true}>
      <div className={style['transition-container']}>
        <div className={style['screen-view']}>
          <img src={ConsCentLogo} className={style['logo']} />
          <div className={textStyle['subHeading']} style={{ marginTop: '15px' }}>
            Your Gateway To Premium Content
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
