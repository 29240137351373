export const minAmountForCurrencies = {
  AED: 0.1,
  USD: 0.5,
  ALL: 2.21,
  ARS: 0.8,
  EUR: 0.5,
  AUD: 0.5,
  AWG: 0.1,
  BBD: 0.1,
  BDT: 1.68,
  BMD: 0.1,
  BND: 0.1,
  BOB: 0.14,
  BSD: 0.1,
  BWP: 0.22,
  BZD: 0.1,
  CAD: 0.5,
  CHF: 1,
  CNY: 0.14,
  COP: 10,
  CRC: 10,
  CUP: 0.53,
  CZK: 0.46,
  DKK: 2.5,
  DOP: 1.02,
  DZD: 2.39,
  EGP: 0.35,
  ETB: 0.57,
  FJD: 0.1,
  GBP: 0.3,
  GHS: 0.03,
  GIP: 0.1,
  GMD: 1,
  GTQ: 0.16,
  GYD: 4.18,
  HKD: 4,
  HNL: 0.49,
  HRK: 0.14,
  HTG: 1.67,
  HUF: 5.55,
  IDR: 10,
  ILS: 0.1,
  INR: 1,
  JMD: 2.5,
  KES: 2.01,
  KGS: 1.4,
  KHR: 10,
  KYD: 0.1,
  KZT: 7.59,
  LAK: 10,
  LBP: 10,
  LKR: 3.58,
  LRD: 3.25,
  LSL: 0.29,
  MAD: 0.2,
  MDL: 0.35,
  MKD: 1.09,
  MMK: 10,
  MNT: 10,
  MOP: 0.17,
  MUR: 0.7,
  MVR: 0.31,
  MWK: 10,
  MXN: 0.39,
  MYR: 0.1,
  NAD: 0.29,
  NGN: 7.23,
  NIO: 0.66,
  NOK: 3,
  NPR: 2.21,
  NZD: 0.5,
  PEN: 0.1,
  PGK: 0.1,
  PHP: 1.06,
  PKR: 2.27,
  QAR: 0.1,
  RUB: 1.3,
  SAR: 0.1,
  SCR: 0.28,
  SEK: 3,
  SGD: 0.5,
  SLL: 10,
  SOS: 10,
  SVC: 0.18,
  SZL: 0.29,
  THB: 0.64,
  TTD: 0.14,
  TZS: 10,
  UYU: 0.67,
  UZS: 10,
  YER: 5.01,
  ZAR: 0.29,
};
