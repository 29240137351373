import React from 'react';
import { Wrapper } from '../components';
import style from './transition.module.scss';
import GreenCheck from '../../../assets/client/greenCheck.svg';
export default function TransitionThankyouForPaypal() {
  return (
    <Wrapper hideCross={true} hideFooter={true}>
      <div className={style['transition-container']} style={{ height: '250px' }}>
        <img src={GreenCheck} alt="GreenCheck" style={{ marginTop: 40 }} />
        <div style={{ marginTop: 20, fontSize: '20px', fontWeight: '500' }}>
          Your purchase has been successfully completed.
        </div>
      </div>
    </Wrapper>
  );
}
