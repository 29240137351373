import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import style from './button.module.scss';
import CSS from 'csstype';

export enum ButtonStyles {
  BLACK = 'black',
  WHITE = 'white',
}

type buttonPropsType = {
  id?: any;
  buttonText?: string;
  disabled?: boolean;
  fixSize?: boolean;
  link?: string;
  buttonStyle?: ButtonStyles;
  customStyle?: CSS.Properties;
  customTextStyle?: CSS.Properties;
  customMobileTextStyle?: CSS.Properties;
  disabledStyle?: CSS.Properties;
  customMobileStyle?: CSS.Properties;
  onClickFunction?: (e: any) => unknown;
  children?: JSX.Element;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

const Button = ({
  id,
  buttonText,
  disabled = false,
  onClickFunction = () => null,
  fixSize = true,
  buttonStyle = ButtonStyles.BLACK,
  customStyle = {},
  customTextStyle = {},
  customMobileTextStyle = {},
  customMobileStyle = {},
  disabledStyle = {},
  children = undefined,
  type = undefined,
}: buttonPropsType): JSX.Element => {
  const isMobile = useSelector((state: RootState) => state.config.isMobile);
  const buttonElement = (
    <button
      id={id}
      className={clsx(style.button, style[buttonStyle], { [style['fix-size']]: fixSize, [style.disabled]: disabled })}
      disabled={disabled}
      onClick={onClickFunction}
      style={{
        ...customStyle,
        ...(isMobile && customMobileStyle && { ...customMobileStyle }),
        ...(disabled && disabledStyle && { ...disabledStyle }),
      }}
      type={type}
    >
      {buttonText && (
        <span
          className={style['button-text']}
          style={{ ...customTextStyle, ...(isMobile && customMobileTextStyle && { ...customMobileTextStyle }) }}
        >
          {buttonText}
        </span>
      )}
      {children}
    </button>
  );

  return buttonElement;
};

// FIXME: display name issue
// eslint-disable-next-line react/display-name
Button.Blue = (props: buttonPropsType) => (
  <Button
    {...props}
    customStyle={{ ...props.customStyle, backgroundColor: '#19B4C8', color: '#fff', fontSize: '18px' }}
    disabledStyle={{
      ...props.disabledStyle,
      backgroundColor: '#19B4C8',
      color: '#fff',
      opacity: 0.6,
      fontSize: '18px',
    }}
  />
);

// eslint-disable-next-line react/display-name
Button.DarkBlue = (props: buttonPropsType) => (
  <Button
    {...props}
    customStyle={{
      ...props.customStyle,
      backgroundColor: '#15213D',
      color: '#fff',
      fontSize: '16px',
      borderRadius: '68px',
    }}
    customTextStyle={{
      ...props.customTextStyle,
    }}
    customMobileTextStyle={{
      ...props.customMobileTextStyle,
    }}
    disabledStyle={{
      ...props.disabledStyle,
      backgroundColor: '#15213D',
      color: '#fff',
      opacity: 0.6,
    }}
  />
);

export { Button };
