import React, { useContext, useState, useEffect } from 'react';
import API from 'api';
import { AddMoney } from '../components/AddMoney';
import { OverlayContext } from '../OverlayContext';
import { antdMessageError } from 'utils/antdMessageComponent';
import PaypalPayment from './PaypalPayment';

export function Balance(): JSX.Element {
  const overlayContext = useContext(OverlayContext);
  const { clientDetails } = overlayContext;
  const [contentPrice, setContentPrice] = useState('');
  const [routing, setRouting] = useState({
    color: '',
    gateway: '',
    logoUrl: '',
  });
  const callRoutingConfig = async () => {
    const routingResponse = await API.user.payment.CallRouting({ clientId: clientDetails.clientId });
    if (!API.validators.checkSuccessCode(routingResponse)) return antdMessageError('Unable To Update Segment');
    setRouting(routingResponse?.data);
  };
  useEffect(() => {
    callRoutingConfig();
  }, []);
  return (
    <>
      {/* <div style={{ textAlign: 'center' }}>
        <AddMoney setContentPrice={setContentPrice} />
      </div> */}
      {routing.gateway === 'RAZORPAY' && (
        <div style={{ textAlign: 'center' }}>
          <AddMoney setContentPrice={setContentPrice} />
        </div>
      )}
      {routing.gateway === 'PAYPAL' && (
        <div style={{ textAlign: 'center' }}>
          <PaypalPayment />
        </div>
      )}
    </>
  );
}
