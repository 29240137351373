import { browserName, osVersion, osName, browserVersion, mobileModel, deviceType } from 'react-device-detect';
const organicSearch = ['google', 'duckduckgo', 'yahoo'];
const socialArray = ['facebook', 'instagram', 'twitter', 'youTube', 'pocket', 'tiktok'];
import moment from 'moment';
export enum eventType {
  VIEW = 'VIEW',
  CLICK = 'CLICK',
  EXIT = 'EXIT',
}
export enum eventLocation {
  PAGE = 'PAGE',
  PAYWALL = 'PAYWALL',
  CONTENT_FLOW_LOGIN = 'CONTENT_FLOW_LOGIN',
  CONTENT_FLOW_OTP = 'CONTENT_FLOW_OTP',
  CONTENT_FLOW_ADD_MONEY_PAGE = 'CONTENT_FLOW_ADD_MONEY_PAGE',
  CONTENT_FLOW_RZP = 'CONTENT_FLOW_RZP',
  SSO_LOGIN = 'SSO_LOGIN',
  SSO_OTP = 'SSO_OTP',
  UDB_LOGIN = 'UDB_LOGIN',
  UDB_OTP = 'UDB_OTP',
  SUBS_LOGIN = 'SUBS_LOGIN',
  SUBS_OTP = 'SUBS_OTP',
  POPUP = 'POPUP',
  SLP = 'SLP',
  SAP = 'SAP',
  SRP = 'SRP',
  SUBS_RZP = 'SUBS_RZP',
  UDB = 'UDB',
  SUBS_PAYMENT_GATEWAY = 'SUBS_PAYMENT_GATEWAY',
}
console.log('check');
export enum clickAction {
  P2P = 'P2P',
  PAYMENT_GATEWAY_CROSS_BTN = 'PAYMENT_GATEWAY_CROSS_BTN',
  LOGIN_FLOW_CROSSBTN = 'LOGIN_FLOW_CROSSBTN',
  LOGOUT = 'LOGOUT',
  CONTENT = 'CONTENT',
  PASS = 'PASS',
  SUBS = 'SUBS',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  BUY_NOW = 'BUY_NOW', //Triggered on buy now button ON SLP!!
  RENEW_BTN = 'RENEW_BTN', // Triggered when renew selection changes(selected/unselected)
  CHANGE_PLAN = 'CHANGE_PLAN', // -- When the selected plan is switched
  ADD_ADDRESS = 'ADD_ADDRESS', // --Triggered when add address button is clicked
  COUPON = 'COUPON', // -- Coupon here is different for content coupon
  ADD_MONEY_TO_WALLET = 'ADD_MONEY_TO_WALLET',
  EDIT = 'EDIT',
  METERING_BANNER_CROSSBTN = 'METERING_BANNER_CROSSBTN',
}

export const getParams = () => {
  try {
    const pageUrlString = new URLSearchParams(location.search).get('pageUrl');
    const pageUrl = pageUrlString ? new URL(decodeURIComponent(pageUrlString)) : undefined;
    const searchParams = pageUrl?.searchParams;
    const currentPageSearchParams = new URL(window.location.href).searchParams;
    const utmParams: Record<string, any> = {};
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    utmKeys.map((utmKey) => {
      if (currentPageSearchParams.get(utmKey)) {
        utmParams[utmKey] = currentPageSearchParams.get(utmKey);
      }
      if (searchParams?.get(utmKey)) {
        utmParams[utmKey] = searchParams.get(utmKey);
      }
    });
    return utmParams;
  } catch (e) {
    return {};
  }
};
export const getUserSourceData = () => {
  const params = getParams();

  // @ts-ignore
  const referrer = window.document.referrer;
  let social = false as boolean;
  let organic = false;
  for (let i = 0; i < socialArray.length; i++) {
    if (referrer.includes(socialArray[i])) {
      social = true;
    }
  }
  for (let i = 0; i < organicSearch.length; i++) {
    if (referrer.includes(organicSearch[i])) {
      organic = true;
    }
  }
  if (organic) {
    return 'ORGANIC';
  } else if (social) {
    return 'SOCIAL';
  } else if (JSON.stringify(params) === '{}' && referrer === '') {
    return 'DIRECT';
  } else {
    return 'OTHERS';
  }
};
console.log('check');
export default function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const getDefaultTrackingValues = () => {
  const searchParams = new URLSearchParams(location.search);
  const userSourceData = searchParams.get('userSourceData') || '';
  const pageType = searchParams.get('pageType') || '';
  return {
    browser: browserName,
    osVersion,
    osName,
    mobileModel,
    deviceType: deviceType.toUpperCase(),
    userAgent: navigator.userAgent,
    url: window.location.href,
    utmSource: getParams().utm_source,
    utmMedium: getParams().utm_medium,
    utmCampaign: getParams().utm_campaign,
    utmTerm: getParams().utm_term,
    utmContent: getParams().utm_content,
    userSourceData: userSourceData || getUserSourceData(),
    isCookieBlocked: +!navigator.cookieEnabled,
    pageType,
  };
};
// export const paywallClickEvent = ({ paywallId }: { paywallId: string }) => {
//   console.log("s,mnf")
// };
