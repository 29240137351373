import React, { useContext, useEffect } from 'react';
import { Wrapper } from '../components';
import TransactionCheck from '../components/check/TransactionCheck';
import { OverlayContext } from '../OverlayContext';

export default function PaymentConfirmed(): JSX.Element {
  const searchParams = new URLSearchParams(location.search);
  const loginChallengeId = searchParams.get('loginChallenge');
  const ampRid = searchParams.get('rid');
  const overlayContext = useContext(OverlayContext);
  const { userDetails, standaloneMode, redirectMode, downloadExists } = overlayContext;

  return (
    <Wrapper hideCross={standaloneMode && !redirectMode}>
      <div style={{ textAlign: 'center' }}>
        {(() => {
          if (downloadExists) {
            return (
              <TransactionCheck
                title="Download Unlocked"
                midText="Your download will begin shortly"
                bottomText={`Feel free to close this window when the file is downloaded`}
              />
            );
          }
          if (loginChallengeId || ampRid) {
            if (!userDetails.hasAccess)
              return (
                <TransactionCheck
                  bottomText={`Please return to the ${ampRid ? 'website' : 'app'} if not redirected automatically`}
                  progressBar
                />
              );
            return (
              <TransactionCheck
                title="Content Unlocked"
                midText="You have access to this content"
                bottomText={`Please return to the ${ampRid ? 'website' : 'app'} if not redirected`}
                progressBar
              />
            );
          }
          return <TransactionCheck progressBar />;
        })()}
      </div>
    </Wrapper>
  );
}
