import React from 'react';
import style from './welcomeHeader.module.scss';

export default function WelcomeHeader({
  content,
  customStyle,
}: {
  content?: string;
  customStyle?: React.CSSProperties;
}) {
  return (
    <div className={style['header-main']} style={customStyle || {}}>
      {content}
    </div>
  );
}
