import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppConfig {
  anonId: string;
  paywallId: string;
  alreadyRegistered: boolean;
  templateId: string;
  paywallType: string;
}

const initialState: AppConfig = {
  anonId: '00000000-0000-0000-0000-000000000000',
  paywallId: '',
  templateId: '',
  alreadyRegistered: false,
  paywallType: '',
};

const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAnalyticsData(
      state,
      action: PayloadAction<{
        anonId?: string;
        paywallId?: string;
        templateId?: string;
        alreadyRegistered?: boolean;
        paywallType?: string;
      }>,
    ) {
      if (action.payload.anonId) {
        state.anonId = action.payload.anonId;
      }
      if (action.payload.paywallId) {
        state.paywallId = action.payload.paywallId;
      }

      if (action.payload.templateId) {
        state.templateId = action.payload.templateId;
      }
      if (action.payload.paywallType) {
        state.paywallType = action.payload.paywallType;
      }
      if (_.includes(Object.keys(action.payload), 'alreadyRegistered')) {
        state.alreadyRegistered = action.payload.alreadyRegistered || false;
      }
    },
  },
});

export const { setAnalyticsData } = analytics.actions;

export default analytics.reducer;
