export const Currencies = <const>[
  { code: 'AED', name: 'United Arab Emirates Dirham', symbol: 'د.إ' },
  { code: 'ALL', name: 'Albanian Lek', symbol: 'Lek' },
  { code: 'ARS', name: 'Argentine Peso', symbol: 'AR$' },
  { code: 'AUD', name: 'Australian Dollar', symbol: 'AU$' },
  { code: 'AWG', name: 'Aruban Florin', symbol: 'ƒ' },
  { code: 'BBD', name: 'Barbadian Dollar', symbol: 'Bds$' },
  { code: 'BDT', name: 'Bangladeshi Taka', symbol: 'Tk' },
  { code: 'BMD', name: 'Bermudan Dollar', symbol: '$' },
  { code: 'BOB', name: 'Bolivian Boliviano', symbol: 'Bs' },
  { code: 'BSD', name: 'Bahamian Dollar', symbol: 'B$' },
  { code: 'BWP', name: 'Botswanan Pula', symbol: 'PKRs' },
  { code: 'BZD', name: 'Belize Dollar', symbol: 'BZ$' },
  { code: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' },
  { code: 'CHF', name: 'Swiss Franc', symbol: 'CHF' },
  { code: 'CNY', name: 'Chinese Yuan', symbol: '¥' },
  { code: 'COP', name: 'Colombian Peso', symbol: 'CO$' },
  { code: 'CRC', name: 'Costa Rican Colón', symbol: '₡' },
  { code: 'CUP', name: 'Cuban Peso', symbol: '$' },
  { code: 'CZK', name: 'Czech Republic Koruna', symbol: 'Kč' },
  { code: 'DKK', name: 'Danish Krone', symbol: 'Dkr' },
  { code: 'DOP', name: 'Dominican Peso', symbol: 'RD$' },
  { code: 'DZD', name: 'Algerian Dinar', symbol: 'DA' },
  { code: 'EGP', name: 'Egyptian Pound', symbol: 'EGP' },
  { code: 'ETB', name: 'Ethiopian Birr', symbol: 'Br' },
  { code: 'EUR', name: 'Euro', symbol: '€' },
  { code: 'FJD', name: 'Fijian Dollar', symbol: 'FJ$' },
  { code: 'GBP', name: 'British Pound Sterling', symbol: '£' },
  { code: 'GHS', name: 'Ghanaian Cedi', symbol: 'GH₵' },
  { code: 'GIP', name: 'Gibraltar Pound', symbol: '£' },
  { code: 'GMD', name: 'Gambian Dalasi', symbol: 'D' },
  { code: 'GTQ', name: 'Guatemalan Quetzal', symbol: 'GTQ' },
  { code: 'GYD', name: 'Guyanaese Dollar', symbol: 'G$' },
  { code: 'HKD', name: 'Hong Kong Dollar', symbol: 'HK$' },
  { code: 'HNL', name: 'Honduran Lempira', symbol: 'HNL' },
  { code: 'HRK', name: 'Croatian Kuna', symbol: 'kn' },
  { code: 'HTG', name: 'Haitian Gourde', symbol: 'G' },
  { code: 'HUF', name: 'Hungarian Forint', symbol: 'Ft' },
  { code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' },
  { code: 'ILS', name: 'Israeli New Sheqel', symbol: '₪' },
  { code: 'INR', name: 'Indian Rupee', symbol: '₹' },
  { code: 'JMD', name: 'Jamaican Dollar', symbol: 'J$' },
  { code: 'KES', name: 'Kenyan Shilling', symbol: 'Ksh' },
  { code: 'KGS', name: 'Kyrgystani Som', symbol: 'Лв' },
  { code: 'KHR', name: 'Cambodian Riel', symbol: 'KHR' },
  { code: 'KYD', name: 'Cayman Islands Dollar', symbol: '$' },
  { code: 'KZT', name: 'Kazakhstani Tenge', symbol: 'KZT' },
  { code: 'LAK', name: 'Laotian Kip', symbol: '₭' },
  { code: 'LBP', name: 'Lebanese Pound', symbol: 'LB£' },
  { code: 'LKR', name: 'Sri Lankan Rupee', symbol: 'SLRs' },
  { code: 'LRD', name: 'Liberian Dollar', symbol: 'L$' },
  { code: 'LSL', name: 'Lesotho Loti', symbol: 'L' },
  { code: 'MAD', name: 'Moroccan Dirham', symbol: 'MAD' },
  { code: 'MDL', name: 'Moldovan Leu', symbol: 'MDL' },
  { code: 'MKD', name: 'Macedonian Denar', symbol: 'MKD' },
  { code: 'MMK', name: 'Myanmar Kyat', symbol: 'K' },
  { code: 'MNT', name: 'Mongolian Tugrik', symbol: '₮' },
  { code: 'MOP', name: 'Macanese Pataca', symbol: 'MOP$' },
  { code: 'MUR', name: 'Mauritian Rupee', symbol: 'MURs' },
  { code: 'MVR', name: 'Maldivian Rufiyaa', symbol: 'Rf' },
  { code: 'MWK', name: 'Malawian Kwacha', symbol: 'MK' },
  { code: 'MXN', name: 'Mexican Peso', symbol: 'MX$' },
  { code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' },
  { code: 'NAD', name: 'Namibian Dollar', symbol: 'N$' },
  { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  { code: 'NIO', name: 'Nicaraguan Córdoba', symbol: 'C$' },
  { code: 'NOK', name: 'Norwegian Krone', symbol: 'Nkr' },
  { code: 'NPR', name: 'Nepalese Rupee', symbol: 'NPRs' },
  { code: 'NZD', name: 'New Zealand Dollar', symbol: 'NZ$' },
  { code: 'PEN', name: 'Peruvian Nuevo Sol', symbol: 'S/' },
  { code: 'PGK', name: 'Papua New Guinean Kina', symbol: 'K' },
  { code: 'PHP', name: 'Philippine Peso', symbol: '₱' },
  { code: 'PKR', name: 'Pakistani Rupee', symbol: 'PKRs' },
  { code: 'QAR', name: 'Qatari Rial', symbol: 'ر.ق,' },
  { code: 'RUB', name: 'Russian Ruble', symbol: '₽' },
  { code: 'SAR', name: 'Saudi Riyal', symbol: 'SR' },
  { code: 'SCR', name: 'Seychellois Rupee', symbol: 'SRe' },
  { code: 'SEK', name: 'Swedish Krona', symbol: 'Skr' },
  { code: 'SGD', name: 'Singapore Dollar', symbol: 'S$' },
  { code: 'SLL', name: 'Sierra Leonean Leone', symbol: 'Le' },
  { code: 'SOS', name: 'Somali Shilling', symbol: 'Ssh' },
  { code: 'SVC', name: 'Salvadoran Colón', symbol: '₡' },
  { code: 'SZL', name: 'Swazi Lilangeni', symbol: 'L' },
  { code: 'THB', name: 'Thai Baht', symbol: '฿' },
  { code: 'TTD', name: 'Trinidad and Tobago Dollar', symbol: 'TT$' },
  { code: 'TZS', name: 'Tanzanian Shilling', symbol: 'TSh' },
  { code: 'USD', name: 'United States Dollar', symbol: '$' },
  { code: 'UYU', name: 'Uruguayan Peso', symbol: '$U' },
  { code: 'UZS', name: 'Uzbekistan Som', symbol: "so'm" },
  { code: 'YER', name: 'Yemeni Rial', symbol: 'YR' },
  { code: 'ZAR', name: 'South African Rand', symbol: 'R' },
];

export const currencyEnums = Currencies.map((currency) => currency.code);

export type currencyType = typeof currencyEnums[number];
export const getCurrencySymbol = (currencyCode: string) => {
  for (let i = 0; i < Currencies.length; i++) {
    if (Currencies[i].code === currencyCode) return Currencies[i].symbol;
  }
};
