import API from 'api';
import { LoginConfigurationFromApitype } from '../components/types';

export const GetLoginCustomizationdata = async ({ clientId }: { clientId?: string }) => {
  const returnData = {
    data: {
      enableEmailLogin: true,
      enableMobileLogin: false,
      enableFacebookLogin: false,
      enableGoogleLogin: false,
      enableExternalGoogleLogin: false,
      enableNameInput: 'SKIP',
      enableSecondaryInput: 'SKIP',
      brandLogoUrl: '',
      googleClientId: '',
      uiConfig: '{}',
      homePageUrl: '',
      googleAnalyticsId: '',
    } as LoginConfigurationFromApitype,
    error: false,
  };

  const response = await API.client.GetLoginCustomization({ clientId });

  if (API.validators.checkSuccessCode(response)) {
    returnData.data = response.data;
  } else {
    returnData.error = true;
  }

  return returnData;
};
