import React, { useEffect, useContext } from 'react';
import style from 'views/UserOverlay/views/loginAndVerify.module.scss';
import { useGoogleLogin } from '@react-oauth/google';
import googleLogo from 'assets/google-logo.svg';
import API from 'api';
import { TermsOfServicesLink } from 'consts';
import CSS from 'csstype';
import { OverlayContext } from 'views/UserOverlay/OverlayContext';
import { Checkbox } from 'antd';
import { getDefaultTrackingValues, eventType, eventLocation } from '../../utils/Tracker/track';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import UserSignUpAndLogin from 'views/ClientDashboard/loginUI/UserSignUpAndLogin';

export const TandCComponent = (props: { checked: boolean; setError?: any; setChecked: any; error?: string }) => (
  <>
    <div className={style['terms-condition']}>
      <Checkbox
        id="sel-term-condition-check"
        type="checkbox"
        className={style['checkbox']}
        checked={props.checked}
        onChange={(e) => {
          if (e.target.checked) props.setError?.('');
          props.setChecked(!props.checked);
        }}
      />
      <p>
        I agree to the
        <a
          href={TermsOfServicesLink}
          target="_blank"
          rel="noreferrer"
          id="sel-term-condition-pdf"
          className={style['cursor-blue-link']}
        >
          Terms of service
        </a>
      </p>
    </div>
  </>
);

export function LoginWithGoogleButton(props: {
  setError?: any;
  postGoogleLogin: (arg0: any, arg1: any) => any;
  customStyle?: CSS.Properties;
  text?: string;
  marginAfterGoogleLogin?: CSS.Properties;
  clientId?: string;
  clientTierId?: string;
  type?: string;
}) {
  const { clientDetails } = useContext(OverlayContext);
  const searchParams = new URLSearchParams(location.search);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId') || '';
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleLoginResponse = await API.auth.GoogleLogin({
        token: tokenResponse.access_token,
        clientId: clientDetails.clientId,
        anonId: anonId,
        eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
        paywallId,
        paywallType: analyticsStore.paywallType,
      });
      if (!API.validators.checkSuccessCode(googleLoginResponse)) return console.error(googleLoginResponse.data.message);
      if (API.validators.checkSuccessCode(googleLoginResponse)) {
        const { token, email, userId, sessionId, expiresAt, version } = googleLoginResponse.data as any;
        await props.postGoogleLogin(
          {
            token,
            email,
            userId,
            sessionId,
            expiresAt,
            version,
          },
          props?.setError,
        );
      } else {
        props.setError('Login failure, please try later');
      }
    },
    flow: 'implicit',
  });

  return (
    <>
      <div
        id="sel-login-google"
        onClick={() => {
          handleGoogleLogin();
        }}
        className={style['google-btn']}
      >
        <img src={googleLogo} alt="googleLogo" className={style['google-logo']} />
        {props.text}
      </div>
    </>
  );
}

export function LoginUI(props: {
  onPhoneSubmit: (phoneNumber: string, setError: (error: string) => any) => any;
  onEmailSubmit: (email: string, setError: (error: string) => any) => any;
  postGoogleLogin: (
    userData: {
      token: string;
      email: string;
      userId: string;
      sessionId: string;
      expiresAt: string;
      version: string;
    },
    setError: (error: string) => any,
  ) => any;
  onLoginMethodChange?: (loginMethod: 'EMAIL' | 'PHONE') => any;
  loginMethod?: 'EMAIL' | 'PHONE';
  validCouponCode?: string;
  detectedCountry?: string;
  marginAfterGoogleLogin?: boolean;
  clientId?: string;
  viewId?: string;
  isOverlay?: boolean;
}) {
  const { clientDetails } = useContext(OverlayContext);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const searchParams = new URLSearchParams(location.search);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId');

  const loginContentView = () => {
    const loginContentViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
      clientId: clientDetails.clientId,
      contentId: clientDetails.clientContentId,
      anonId: anonId,
      paywallId: paywallId,
      paywallType: analyticsStore.paywallType,
    };
    API.analyst.eventPost({ ...loginContentViewObject });
  };
  useEffect(() => {
    loginContentView();
  }, []);

  return (
    <>
      <form>
        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
          <div className={style['wrap-label']}>{<UserSignUpAndLogin type="CONTENT_FLOW" />}</div>
        </div>
      </form>
    </>
  );
}
