import React, { useState, useRef, useEffect } from 'react';
import style from './login.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from 'views/Auth/authSlice';
import API from 'api';
import ConscentAiLogo from '../../assets/conscentAiLogo.svg';
import { consts } from 'utils';
import { isValidEmail } from 'utils/regularExpressions';
import { message } from 'antd';
import { ReactComponent as EyeIcon } from 'assets/eyeIcon.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/eyeCrossIcon.svg';
import clsx from 'clsx';
export default function EmailLogin(props: { loginType: 'CLIENT' | 'ADMIN' }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isClient = props.loginType === 'CLIENT';
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const searchParams = new URLSearchParams(location.search);
  const nextUrl = searchParams.get('nextUrl');
  const [showPassword, setShowPassword] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    buttonRef?.current?.focus();
  }, []);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && buttonRef.current && email && password && isValidEmail(email)) {
      buttonRef?.current?.click();
    }
  };
  const onFinish = async ({ email, password }: { email: string; password: string }) => {
    setLoading(true);
    const loginResponse = isClient
      ? await API.auth.ClientLogin({ email, password })
      : await API.auth.AdminLogin({ email, password });
    setLoading(false);

    if (!API.validators.checkSuccessCode(loginResponse))
      return message.error({
        content: 'Invalid Credentials',
      });
    const loginResponseData = loginResponse.data as {
      token: string;
      clientId: string;
      expiresAt: string;
      version: number;
    };
    if (isClient) {
      dispatch(setAuthDetails({ ...loginResponseData, email, userType: 'CLIENT' }));
      return history.push(consts.CLIENT_HOME_PATH + '/' + nextUrl);
    } else {
      dispatch(setAuthDetails({ ...loginResponseData, email, userType: 'ADMIN' }));
      return history.push(consts.ADMIN_HOME_PATH);
    }
  };
  return (
    <>
      <div className={style['main-container']}>
        <div>
          <img src={ConscentAiLogo} alt="logo" style={{ width: '188px', height: '24px' }} />
        </div>

        <div className={style['login-container']}>
          <div className={style['field-container']}>
            <div>
              <div className={style['head-box']}>Email</div>
              <div>
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                  required
                  type="text"
                  className={style['input-box']}
                />
              </div>
            </div>
            <div>
              <div className={style['head-box']}>Password</div>
              <div className={style['inputContainer']}>
                <input
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                  required
                  className={style['password-box']}
                />
                <span className={style['icon-container']} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeIcon className={style['icon']} /> : <EyeCrossIcon className={style['icon']} />}
                </span>
              </div>
            </div>
            <div>
              <button
                style={email && password && isValidEmail(email) ? {} : { cursor: 'no-drop', opacity: '0.3' }}
                onClick={() => onFinish({ email: email, password: password })}
                ref={buttonRef}
                className={style['login-btn']}
              >
                <div className={style['login-txt']}>Login</div>
              </button>
              <br></br>
            </div>
          </div>
        </div>

        {/* <div className={style['Register-txt']}>
          Not a partner yet?{' '}
          <a style={{ cursor: 'pointer' }} href={'./'}>
            {' '}
            Register
          </a>
        </div> */}
      </div>
    </>
  );
}
