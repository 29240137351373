import React, { createContext, useState, useEffect } from 'react';
import API from 'api';
import { postMessage } from '../UserOverlay/paywallUtils';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { currencyType } from 'data/currencies';
import { userActions } from '../../consts';

export const defaultUserDetails = {
  wallet: {
    balance: {
      $numberDecimal: '0',
    },
    currency: '' as currencyType,
  },
  phoneNumber: '',
  email: '',
  _id: '',
  name: '',
  address: {
    apartment: '',
    area: '',
    city: '',
    state: '',
    country: '',
    landmark: '',
    pincode: '',
  },
  defaultEmail: '',
  defaultPhone: '',
  defaultName: '',
  userDetailsSet: false,
};

export enum CONSCENTLOGIN_ROUTES {
  LOGIN = 'login',
  THANKYOU = 'thankYou',
  DEFAULT_BLANK = '',
}

export const ConscentLoginContext = createContext({
  userDetails: defaultUserDetails,
  setUserDetails: (o: any) => undefined as any,
  route: CONSCENTLOGIN_ROUTES.LOGIN as CONSCENTLOGIN_ROUTES,
  setRoute: (o: CONSCENTLOGIN_ROUTES) => undefined as any,
  clientId: '',
  viewId: '',
  updateView: (o: { action: userActions; userId?: string }) => undefined as any,
});

interface Props {
  children: JSX.Element;
}

export const ContextWrapper = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState(defaultUserDetails);
  const authDetails = useSelector((store: RootState) => store.auth);
  const searchParams = new URLSearchParams(location.search);
  const [viewId, setViewId] = useState(searchParams.get('viewId') || '');
  const [route, setRoute] = useState<CONSCENTLOGIN_ROUTES>(CONSCENTLOGIN_ROUTES.DEFAULT_BLANK);
  const [clientId, setClientId] = useState(searchParams.get('clientId') || '');

  const getUtmParameters = () => {
    try {
      const siteUrlString = new URLSearchParams(location.search).get('siteUrl');
      const siteUrl = siteUrlString ? new URL(decodeURIComponent(siteUrlString)) : undefined;
      const searchParams = siteUrl?.searchParams;
      const utmParams: Record<string, any> = {};
      const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      utmKeys.map((utmKey) => {
        if (searchParams?.get(utmKey)) {
          utmParams[utmKey] = searchParams.get(utmKey);
        }
      });
      return utmParams;
    } catch (e) {
      return {};
    }
  };

  useEffect(() => {
    (async () => {
      if (!viewId) {
        let visitId;
        let visitorId;
        try {
          visitId = sessionStorage.getItem('visitId');
          if (!visitId) {
            visitId = uuid();
            sessionStorage.setItem('visitId', visitId as string);
          }
          visitorId = window.localStorage.getItem('visitorId');
          if (!visitorId) {
            visitorId = uuid();
            window.localStorage.setItem('visitorId', visitorId as string);
          }
        } catch (e) {
          console.error('Unable to record viewId because cookies are blocked');
        }

        const createViewResponse = await API.content.CreateView({
          clientId: searchParams.get('clientId') as string,
          clientContentId: searchParams.get('clientContentId') as string,
          subscription: true,
          sessionId: authDetails.sessionId,
          utmParameters: getUtmParameters(),
          ...(visitId && { visitId }),
          ...(visitorId && { visitorId }),
        });
        postMessage.CREATE_VIEW(createViewResponse.data.viewId);
        window.dispatchEvent(new CustomEvent('view-id-created', { detail: createViewResponse.data.viewId }));
        setViewId(createViewResponse.data.viewId);

        try {
          sessionStorage.setItem('viewId', createViewResponse.data?.viewId);
        } catch (error) {
          console.error('Unable to record viewId because cookies are blocked');
        }
      }
    })();
  }, []);

  const updateView = async ({ action, userId }: { action: userActions; userId?: string }) => {
    let newViewId = '';
    if (!viewId) {
      newViewId = await new Promise((res) => {
        window.addEventListener('view-id-created', (e) => {
          //@ts-ignore
          res(e.detail);
        });
      });
    }
    const updateViewResponse = await API.content.UpdateView({
      viewId: viewId || newViewId,
      action,
      userId: authDetails.userId || userId,
      sessionId: authDetails.sessionId,
    });
    const userData = updateViewResponse?.data?.view?.userData;
    setUserDetails({
      ...userDetails,
      defaultEmail: userData?.defaultEmail,
      defaultPhone: userData?.defaultPhone,
      defaultName: userData?.defaultName,
      userDetailsSet: true,
    });
  };

  return (
    <ConscentLoginContext.Provider
      value={{
        userDetails,
        setUserDetails,
        route,
        setRoute,
        viewId: viewId,
        updateView,
        clientId: clientId as string,
      }}
    >
      {children}
    </ConscentLoginContext.Provider>
  );
};
