import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface globalCurrency {
  globalCurrency: string;
}

const initialState: globalCurrency = {
  globalCurrency: '',
};

const currencyReducer = createSlice({
  name: 'currencyReducer',
  initialState,
  reducers: {
    setGlobalCurrency(state, action: PayloadAction<globalCurrency>) {
      state.globalCurrency = action.payload.globalCurrency;
    },
  },
});

export const { setGlobalCurrency } = currencyReducer.actions;

export default currencyReducer.reducer;
