import React, { useEffect } from 'react';
import clsx from 'clsx';
import style from './progressBarBtn.module.scss';
export const ProgressBarBtn = ({ backgroundColor }: { backgroundColor?: string }) => {
  return (
    <div className={style['progress-container']}>
      <div className={style['progress']}>
        <div
          style={{ backgroundColor: backgroundColor ? backgroundColor : '' }}
          className={clsx(style['progress-bar'], style['progress-bar-animated'])}
          role="progressbar"
        >
          Verifying…
        </div>
      </div>
    </div>
  );
};
